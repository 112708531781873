import {
  trackEvent,
  validateViewForEmailCapture,
  setEmailCaptureModalCookie,
  setPhoneCaptureModalCookie
} from '@components/EmailCapture/utils';

import dataToolSvc from '@services/dataToolSvc';

import { getObjProperty } from "@utilities/mrVueUtils";

export const state = {
  isEmailModalVisible: false,
  isPhoneModalVisible: false,
  isSuccessModalVisible: false,
  experiments: undefined,
  subscribed: false,
  subscribedToSms: false,
  phoneModalConfig: null,
  configs: {},
  configsLoaded: false,
};

export const getters = {
  colorAdvisorModalConfig: (state) => {
    if (!state.configs || !state.configs.EMAIL_CAPTURE_COLOR_ADVISOR) {
      return null;
    }
    return state.configs.EMAIL_CAPTURE_COLOR_ADVISOR;
  },
  getModalConfig: (state) => (params = {}) => {
    const { configKey } = params;
    const phoneConfig = (state.configs.PHONE_CAPTURE_KEY_CONFIGS || {})[configKey];
    const emailConfig = state.configs.EMAIL_CAPTURE_DEFAULT_CONFIG;

    if (configKey && phoneConfig) {
      return phoneConfig;
    }

    if (!window) {
      return emailConfig;
    }

    const currentPathName = window.location.pathname;
    const { config } = (state.configs.EMAIL_CAPTURE_PATH_CONFIGS || []).find(({ paths }) => paths.some(path => currentPathName.indexOf(path) === 0)) || {};
    return config || emailConfig;
  },
};

export const actions = {
  trackEvent({ state }, eventName) {
    const { experiments } = state;
    trackEvent(eventName, experiments);
  },
  manualCloseEmailModal({ commit }) {
    commit('setIsEmailModalVisible', false);
  },

  closeEmailModal({ commit, dispatch }) {
    setEmailCaptureModalCookie();
    dispatch('trackEvent', 'emailCaptureModalClose');
    //- New Segment event (bellow) for email capture modal view, we should remove the old (above) one once we have enough data
    dispatch('trackEvent', 'Email Capture - Email Modal Close');
    commit('setIsEmailModalVisible', false);
  },

  closePhoneModal({ commit, dispatch }) {
    setPhoneCaptureModalCookie();
    dispatch('trackEvent', 'Email Capture - Phone Modal Close');
    commit('setIsPhoneModalVisible', false);
  },

  openEmailModal({ commit, dispatch, rootState }) {
    const colorMatch = getObjProperty(rootState, 'customer.cdata.bestColorMatch') || {};
    const product = getObjProperty(rootState, 'pdp.product') || {};
    const currentPathName = window.location.pathname;
    if (currentPathName.startsWith('/product') && colorMatch.code === product.code) {
      return;
    }
    dispatch('trackEvent', 'emailCaptureModalView');
    //- New Segment event (bellow) for email capture modal view, we should remove the old (above) one once we have enough data
    dispatch('trackEvent', 'Email Capture - Email Modal View');
    commit('setIsEmailModalVisible', true);
  },

  openPhoneModal({ commit, dispatch }) {
    dispatch('trackEvent', 'Email Capture - Phone Modal View');
    commit('setIsPhoneModalVisible', true);/**/
  },

  openSuccessModal({ commit, dispatch }) {
    dispatch('trackEvent', 'Email Capture - Success Modal View');
    commit('setIsSuccessModalVisible', true);
  },

  runEmailCapture({ state, commit, dispatch, rootState, getters }) {
    dataToolSvc.getData({ mixinKey: 'email_phone_capture' }).then(res => {
      commit('setConfigsLoaded', true);
      commit('setConfigs', res.data);
      const cdata = getObjProperty(rootState, 'customer.cdata') || {};
      const isHCB = getters.getModalConfig().emailSource?.includes("HCB");
      const showEmailCapture = validateViewForEmailCapture({ cdata, configs: state.configs, isHCB });

      if (!showEmailCapture) {
        return;
      }

      let delay = state.configs.EMAIL_CAPTURE_MODAL_DELAY;

      modalTimeout = setTimeout(() => {
        dispatch('openEmailModal');
        clearTimeout(modalTimeout);
      }, delay);
    }).catch(err => {
      dispatch('notifyError', { silent: true, err }, { root: true });
    });
  },

  runPhoneCapture({ state, dispatch, commit, rootState }, params = {}) {
    const cdata = getObjProperty(rootState, 'customer.cdata') || {};
    const payload = {
      cdata,
      configs: state.configs,
      rules: state.configs.PHONE_CAPTURE_RULES,
    };

    const showPhoneCapture = validateViewForEmailCapture(payload);
    if (!showPhoneCapture) {
      return;
    }

    commit('setPhoneModalConfig', params.config || 'phoneCapture');
    setTimeout(() => {
      dispatch('openPhoneModal');
    }, 800);
  },

  runHcbPhoneCapture({ state, dispatch }) {
    if (state.configs?.MASTER_TOGGLE?.show_hcb_modal) {
      dispatch('runPhoneCapture', { config: 'phoneCaptureHcb' });
    }
  },

  processOnSuccessAfterClose({ dispatch }, params = {}) {
    if (params.method) {
      dispatch(params.method, params.payload);
    }
  },
};

let modalTimeout;
export const mutations = {
  setSubscribed(state, val) {
    // Just in case the customer uses the footer form before the modal is displayed
    clearTimeout(modalTimeout);
    state.subscribed = !!val;
  },

  setConfigs(state, configs) {
    state.configs = configs;
  },

  setSubscribedToSms(state, val) {
    clearTimeout(modalTimeout);
    state.subscribedToSms = !!val;
  },

  setIsEmailModalVisible(state, val) {
    state.isEmailModalVisible = !!val;
  },

  setIsPhoneModalVisible(state, val) {
    state.isPhoneModalVisible = !!val;
  },

  setIsSuccessModalVisible(state, val) {
    state.isSuccessModalVisible = !!val;
  },

  setExperiments(state, experiments) {
    state.experiments = experiments;
  },

  setPhoneModalConfig(state, configKey) {
    const config = (state.configs.PHONE_CAPTURE_KEY_CONFIGS || {})[configKey];
    state.phoneModalConfig = config || {};
  },

  setConfigsLoaded(state, val) {
    state.configsLoaded = Boolean(val);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
